<template>
  <div>
    <v-container>
      <!-- 担当者登録画面 -->
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>担当者登録</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <!-- 担当者コード -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.managerCode"
                      label="担当者コード"
                      :hint="`登録すると自動採番されます`"
                      persistent-hint
                      disabled
                      tabindex="1"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 部門コード -->
                  <v-col cols="12" sm="12" v-if="isLoginUserAuth && !isSelectedAuthUser">
                    <v-autocomplete
                      v-model="currentParams.departmentCode"
                      label="部門コード"
                      :rules="[required]"
                      :items="departmentCodeList"
                      item-text="comboItem"
                      item-value="code"
                      tabindex="2"
                      ref="departmentCode"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" v-else>
                    <v-autocomplete
                      v-model="currentParams.departmentCode"
                      label="部門コード"
                      :items="departmentCodeList"
                      item-text="comboItem"
                      item-value="code"
                      hint="管理者を登録する場合、全部門にユーザーが作成されます"
                      persistent-hint
                      disabled
                      tabindex="3"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- 権限区分 -->
                  <v-col cols="12" sm="12" v-if="(isLoginUserAuth && reqUpdate && !isCurrentAuthUser) || (isLoginUserAuth && !reqUpdate)">
                    <v-autocomplete
                      v-model="currentParams.managerAuthSection"
                      label="権限区分"
                      :rules="[required]"
                      :items="managerAuthSectionList"
                      item-text="comboItem"
                      item-value="code"
                      tabindex="4"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" v-else>
                    <v-autocomplete
                      v-model="currentParams.managerAuthSection"
                      label="権限区分"
                      :rules="[required]"
                      :items="managerAuthSectionList"
                      item-text="comboItem"
                      item-value="code"
                      disabled
                      tabindex="5"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- 担当者名（姓） -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="currentParams.managerFamilyName"
                      label="担当者名（姓）"
                      :rules="[required, limit_length4]"
                      counter="4"
                      clearable
                      tabindex="6"
                      ref="managerFamilyName"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 担当者メールアドレス (新規登録)-->
                  <v-col cols="12" sm="8" v-if="!reqUpdate">
                    <v-text-field
                      v-model="currentParams.managerAddress"
                      label="担当者メールアドレス"
                      :rules="[required, limit_length254, regex_email]"
                      counter="254"
                      clearable
                      :readonly="currentParams.managerCode !== null"
                      tabindex="7"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 担当者メールアドレス -->
                  <v-col cols="12" sm="8" v-else>
                    <v-text-field
                      v-model="currentParams.managerAddress"
                      label="担当者メールアドレス"
                      persistent-hint
                      disabled
                      tabindex="8"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 承認者名（姓） -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="currentParams.authorizerFamilyName"
                      label="承認者名（姓）"
                      :rules="[limit_length4]"
                      counter="4"
                      clearable
                      tabindex="9"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 承認者メールアドレス -->
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="currentParams.authorizerAddress"
                      label="承認者メールアドレス"
                      :rules="[limit_length254, regex_email]"
                      counter="254"
                      clearable
                      tabindex="10"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 作成日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.createdDate"
                      label="作成日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 更新日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.updatedDate"
                      label="更新日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- 登録 -->
              <v-btn
                color="secondary"
                v-on:click="onClickRegister"
                rounded
                class="my-2"
                tabindex="11"
              >
                <v-icon left> mdi-database-edit </v-icon>登録
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// graphqlのインポート
import { Amplify, API, Auth, graphqlOperation } from "aws-amplify";
import AWS from 'aws-sdk';
import {
  listM_SHOKISETTEI,
  listM_TANTOSHA,
  getM_TANTOSHA_EXIST_LOGIN_USER
} from "@/graphql/queries";
import { executeTransactSql } from "@/graphql/mutations";
import { AUTH_USER_DEFAULT_DEPARTMENT, AWS_DEFAULT_REGION, MANAGER_KIND_LIST, REGISTER_COMPLETE_LOGIN_URL, REGISTER_COMPLETE_FROM_ADDRESS } from '@/assets/js/const';
import { setUserData } from '@/assets/js/common';
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_CLEAR_DATA, CONFIRM_MESSAGE_REGISTER_AUTH_USER, CONFIRM_MESSAGE_UPGRADE_AUTH_USER, ALERT_MESSAGE_COMMON_ERROR, ALERT_COMPLETE_CRUD_DATA, ALERT_ALREADY_SAVED_DATA } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { base64Decode } from '@/assets/js/common';
export default {
  name: "ManagerInsert",

  data: () => ({
    // 変数定義
    currentParams: {
      // 担当者コード
      managerCode: null,
      // 部門コード
      departmentCode: null,
      // 権限区分
      managerAuthSection: null,
      // 担当者名（姓）
      managerFamilyName: null,
      // 担当者メールアドレス
      managerAddress: null,
      // 承認者名（姓）
      authorizerFamilyName: null,
      // 承認者メールアドレス
      authorizerAddress: null,
      // 作成日付
      createdDate: null,
      // 更新日付
      updatedDate: null,
    },

    // 未保存チェック用
    originalParams: {},
    originalDepartmentCode: null,

    // 新規登録用部門コードリスト
    departmentCodeList: [],
    // 新規登録用権限リスト
    managerAuthSectionList: MANAGER_KIND_LIST,

    // ログインユーザー情報
    loginUserInfo: null,

    // 管理者の登録キャンセルフラグ
    isCancelRegisterAuth: false,

    // 修正画面表示用フラグ
    reqUpdate: false,

    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 文字数の制約 最大7文字
    limit_length4: (value) =>
      value == null || value.length <= 4 || "4文字以内で入力してください",
    // 文字数の制約 最大254文字
    limit_length254: (value) =>
      value == null || value.length <= 254 || "254文字以内で入力してください",
    // E-mailの正規表現
    regex_email: (value) =>
      !value ||
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) ||
      "E-mailの形式で入力してください",
  }),

  computed: {
    /**
     * ログイン中ユーザーの管理者チェック
     */
    isLoginUserAuth() {
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.$store.getters.user.TANTOSHA_AUTH_KBN
      );
      // リストに存在しない区分はfalse
      return loginUser ? loginUser.isAuthUser : false;
    },
    /**
     * オートコンプリートで選択中の権限区分チェック
     */
    isSelectedAuthUser() {
      // リスト未生成時はfalse
      if (!this.managerAuthSectionList) return false;
      let currentManagerAuth = this.managerAuthSectionList.find(
        (items) => items.code == this.currentParams.managerAuthSection
      );
      // リストに存在しない区分はfalse
      return currentManagerAuth ? currentManagerAuth.isAuthUser : false;
    },
    /**
     * 登録データの権限区分チェック
     */
    isCurrentAuthUser() {
      // リスト未生成時はfalse
      if (!this.managerAuthSectionList) return false;
      let originalAuth = this.managerAuthSectionList.find(
        (items) => items.code == this.originalParams.managerAuthSection
      );
      // リストに存在しない区分はfalse
      return originalAuth ? originalAuth.isAuthUser : false;
    }
  },
  /**
   * created ライフサイクルフック
   */
  created: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // 修正画面表示用フラグを更新
    this.reqManagerItemString = this.$route.query.reqManagerItemString
    this.reqUpdate = this.isRequestedUpdate();
    // ローディングの開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!this.validateCheck()) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディング開始
        this.$store.commit("setLoading", true);
        // 一般ユーザーかつ、部門コード36で登録した場合エラー
        if (this.currentParams.managerAuthSection === '011' && this.currentParams.departmentCode === 36) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert("東北商品センターには一般ユーザーは登録できません。")
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 修正時の判定
        if (
          (await this.isRequestedUpdate()) ||
          typeof this.currentParams.managerCode == "number"
        ) {
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        } else {
          // 新規登録：入力データを登録
          let returnFlg = await this.createUser();
          switch (returnFlg) {
            case 0: // 通常エラー
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // エラーダイアログを表示
              alert(ALERT_MESSAGE_COMMON_ERROR);
              // ログ出力(method-end)
              addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
              return;
            case 1: // 担当者アドレス重複
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // データ重複ダイアログを表示
              alert("そのメールアドレスは既に登録されています。");
              // ログ出力(method-end)
              addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
              return;
            default:
              break;
          }
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        if (!this.isCancelRegisterAuth) {
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        } else {
          // 完了ダイアログ表示用にフラグを戻す
          this.isCancelRegisterAuth = false;
        }
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (this.loginUserInfo !== null);
    },
    /**
     * 初期化処理
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 新規登録時は所属部門選択用に部門コードリストを取得
      // 検索条件
      let condition = {
        where_options: " ORDER BY BUMON_CD ASC",
      };

      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // コンボボックス用リストの取得
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.listM_SHOKISETTEI) {
          // データを取得できた場合
          // 部門コード選択用コンボボックスのリストに追加
          let resultData = result.data.listM_SHOKISETTEI;
          for (const data of resultData) {
            this.departmentCodeList.push({
              comboItem: data.BUMON_CD + "：" + data.BUMON_NAME,
              code: data.BUMON_CD,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 一般ユーザーによる登録の場合、部門コード・権限区分をログインユーザーと同じ値で設定
      let currentManager = this.managerAuthSectionList.find(
        (items) => items.code == this.loginUserInfo.TANTOSHA_AUTH_KBN
      );
      if (currentManager && !currentManager.isAuthUser) {
        this.currentParams.departmentCode = this.loginUserInfo.BUMON_CD;
        this.currentParams.managerAuthSection = this.loginUserInfo.TANTOSHA_AUTH_KBN;
      }

      this.reqManagerItemString = this.$route.query.reqManagerItemString
      if (this.isRequestedUpdate()) {
        // 既存データの更新の場合、マスタを照会し、初期値を更新

        // 引数のitemオブジェクトをデコード
        const itemString = await base64Decode(this.reqManagerItemString);
        const managerItem = JSON.parse(itemString);

        // 検索条件
        let whereOptions = '';
        whereOptions += `AND LOGIN_USER_CD = '${managerItem.loginUserCode}' `;
        whereOptions += `AND BUMON_CD = ${managerItem.departmentCode} `;
        whereOptions += `AND TANTOSHA_CD = ${managerItem.managerCode} `;
        let condition = {
          where_options: whereOptions,
        };

        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // データ取得
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(listM_TANTOSHA, condition)
          );

          // 結果判定
          if (result.data.listM_TANTOSHA) {
            // データを取得できた場合
            // DBから取得した値を設定
            let resultData = result.data.listM_TANTOSHA[0];
            console.log({resultData});
            this.currentParams = {
              loginUserCode: resultData.LOGIN_USER_CD,
              managerCode: resultData.TANTOSHA_CD,
              departmentCode: resultData.BUMON_CD,
              managerAuthSection: resultData.TANTOSHA_AUTH_KBN,
              managerFamilyName: resultData.TANTOSHA_NAME,
              managerAddress: resultData.TANTOSHA_ADDRESS,
              authorizerFamilyName: resultData.SHONINSHA_SIGN_NAME ? resultData.SHONINSHA_SIGN_NAME : '',
              authorizerAddress: resultData.SHONINSHA_ADDRESS ? resultData.SHONINSHA_ADDRESS : '',
              createdDate: resultData.CREATE_DATE,
              createdUser: resultData.CREATE_USER_NAME,
              updatedDate: resultData.UPDATE_DATE,
              updatedUser: resultData.UPDATE_USER_NAME,
            };
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          } else {
            if (result.errors) {
              // データを取得できなかった場合
              // @TODO データ取得無しの場合の処理を以下に記載。
              // ログ出力(SQL実行でエラー)
              addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            } else {
              // データ0件時
              // ログ出力(SQL-end_0)
              addLog('INFO', {message: MSG.INFO["1005"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            }
          }
        } catch (error) {
          // Exception発生の場合
          // @TODO 異常系の処理を以下に記載。
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }
      }

      /* オートフォーカス設定
        ・ログインユーザーが一般ユーザー → 担当者名
        ・ログインユーザーが管理者かつ編集対象が管理者 → 担当者名
        ・それ以外 → 部門コード
      */
      // ログインユーザーの権限区分チェック
      console.log(`this.$store.getters.user.TANTOSHA_AUTH_KBN = ${this.$store.getters.user.TANTOSHA_AUTH_KBN}`);
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.$store.getters.user.TANTOSHA_AUTH_KBN
      );
      // リストに存在しない区分はfalse
      const authLoginUser = loginUser ? loginUser.isAuthUser : false;

      // 登録データの権限区分チェック
      let authUser = false;
      if (this.managerAuthSectionList) {
        console.log(`this.originalParams.managerAuthSection = ${this.originalParams.managerAuthSection}`);
        let originalAuth = this.managerAuthSectionList.find(
          (items) => items.code == this.currentParams.managerAuthSection
        );
        // リストに存在しない区分はfalse
        authUser = originalAuth ? originalAuth.isAuthUser : false;
      }

      console.log({authLoginUser});
      console.log({authUser});
      console.log(this.reqUpdate);
      if(!authLoginUser || (authUser && authLoginUser && this.reqUpdate)) {
        this.$refs.managerFamilyName.focus();
      } else {
        this.$refs.departmentCode.focus();
      }

      // 入力前のデータを保存
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // メソッド名を定義
      const method_name="onClickRegister"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      if (!this.validateCheck()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);
      // 一般ユーザーかつ、部門コード36で登録した場合エラー
      if (this.currentParams.managerAuthSection === '011' && this.currentParams.departmentCode === 36) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // エラーダイアログを表示
        alert("東北商品センターには一般ユーザーは登録できません。")
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 修正ボタン押下もしくは連続登録しなかった場合
      if (
        (await this.isRequestedUpdate()) ||
        typeof this.currentParams.managerCode == "number"
      ) {
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        if (!this.isCancelRegisterAuth) {
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        } else {
          // 完了ダイアログ表示用にフラグを戻す
          this.isCancelRegisterAuth = false;
        }
      } else {
        // 新規登録：入力データを登録
        let returnFlg = await this.createUser();
        switch (returnFlg) {
          case 0: // 通常エラー
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          case 1: // 担当者アドレス重複
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // データ重複ダイアログを表示
            alert("そのメールアドレスは既に登録されています。");
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          default:
            break;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        if (!this.isCancelRegisterAuth) {
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
          
          if (confirm(CONFIRM_MESSAGE_CLEAR_DATA)) {
            // 連続登録の場合
            // ローディングの開始
            this.$store.commit("setLoading", true);
            // 画面上のデータを初期化
            this.currentParams = {
              loginUserCode: null,
              managerCode: null,
              departmentCode: null,
              managerFamilyName: null,
              managerAddress: null,
              managerAuthSection: null,
              authorizerFamilyName: null,
              authorizerAddress: null,
              createdDate: null,
              createdUser: null,
              updatedDate: null,
              updatedUser: null,
            };
            // 未保存チェック用配列を更新
            this.saveOriginalParams();

            // バリデーションエラーをリセット
            this.$refs.form.resetValidation();
          } else {
            // 修正画面用フラグを立てる
            this.reqUpdate = true;
          }
        } else {
          // 完了ダイアログ表示用にフラグを戻す
          this.isCancelRegisterAuth = false;
        }
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 新規登録処理
     */
    async insertTable() {
      // メソッド名を定義
      const method_name="insertTable"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let managerAddress = this.currentParams.managerAddress;
      let userInfo = managerAddress.substring(0, managerAddress.indexOf("@"))
      // 既に登録済みのE-mailの場合処理を抜ける
      condition = { LOGIN_USER_CD: userInfo };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let existEmail = await API.graphql(
          graphqlOperation(getM_TANTOSHA_EXIST_LOGIN_USER, condition)
        );
        // 結果判定
        if (existEmail.data.getM_TANTOSHA_EXIST_LOGIN_USER) {
          // データを取得できた場合処理を抜ける
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "getM_TANTOSHA_EXIST_LOGIN_USER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return 1;
        } else {
          if (existEmail.errors) {
            // データを取得できなかった場合処理を続行
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_TANTOSHA_EXIST_LOGIN_USER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return 0;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_TANTOSHA_EXIST_LOGIN_USER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_TANTOSHA_EXIST_LOGIN_USER", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return 0;
      }

      // 管理者登録用の処理
      let currentManager = this.managerAuthSectionList.find(
        (items) => items.code == this.currentParams.managerAuthSection
      );
      if (currentManager && currentManager.isAuthUser) {
        if (confirm(CONFIRM_MESSAGE_REGISTER_AUTH_USER)) {
          return await this.insertAuthUser();
        } else {
          // 完了ダイアログを表示しないようフラグを立てる
          this.isCancelRegisterAuth = true;
          return 2;
        }        
      }

      // コード最大値の取得
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.loginUserInfo.TANTOSHA_AUTH_KBN
      );
      let departmentCode = (loginUser && loginUser.isAuthUser) ? this.currentParams.departmentCode : this.loginUserInfo.BUMON_CD;
      let currentMaxCode = await this.getCurrentMaxManagerCode(departmentCode);
      if (!currentMaxCode) {
        return 0;
      }

      // DBに入力データ登録
      // 登録データ作成
      let registerLoginUserCode = this.currentParams.managerAddress.split("@")[0];
      let insertSql = '';
      insertSql += `INSERT INTO M_TANTOSHA VALUES (`;
      insertSql += `'${registerLoginUserCode}',`;                      // LOGIN_USER_CD
      insertSql += `${departmentCode},`;                               // BUMON_CD
      insertSql += `${currentMaxCode + 1},`;                           // TANTOSHA_CD
      insertSql += `'${this.currentParams.managerFamilyName}',`;       // TANTOSHA_NAME
      insertSql += `'${this.currentParams.managerAddress}',`;          // TANTOSHA_ADDRESS
      insertSql += `'${this.currentParams.managerAuthSection}',`;      // TANTOSHA_AUTH_KBN
      if(this.currentParams.authorizerFamilyName) {                    // SHONINSHA_SIGN_NAME
        insertSql += `'${this.currentParams.authorizerFamilyName}',`;
      } else {
        insertSql +=  'null,';
      }
      if(this.currentParams.authorizerAddress) {                       // SHONINSHA_ADDRESS
        insertSql += `'${this.currentParams.authorizerAddress}',`;
      } else {
        insertSql += 'null,';
      }
      insertSql += ` CURDATE(),`;                                      // CREATE_DATE
      insertSql += ` '${this.loginUserInfo.LOGIN_USER_CD}',`;          // CREATE_USER_NAME
      insertSql += ` CURDATE(),`;                                      // UPDATE_DATE
      insertSql += ` '${this.loginUserInfo.LOGIN_USER_CD}')`;          // UPDATE_USER_NAME

      let selectSql = '';
      selectSql += `SELECT * FROM M_TANTOSHA `;
      selectSql += ` WHERE LOGIN_USER_CD = '${registerLoginUserCode}'`;
      selectSql += ` AND BUMON_CD = ${departmentCode}`;
      selectSql += ` AND TANTOSHA_CD = ${currentMaxCode + 1}`;

      let sqlList = [insertSql, selectSql];
      let condition = {
        SQLs: sqlList,
      };
      console.log("sqlList = " + sqlList)
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return 0;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
              // DBから取得した値を設定
              let resultData = responseBody.data[1][0];
              // DBから取得した値を設定
              this.currentParams = {
                loginUserCode: resultData.LOGIN_USER_CD,
                managerCode: resultData.TANTOSHA_CD,
                departmentCode: resultData.BUMON_CD,
                managerFamilyName: resultData.TANTOSHA_NAME,
                managerAddress: resultData.TANTOSHA_ADDRESS,
                managerAuthSection: resultData.TANTOSHA_AUTH_KBN,
                authorizerFamilyName: resultData.SHONINSHA_SIGN_NAME,
                authorizerAddress: resultData.SHONINSHA_ADDRESS,
                createdDate: resultData.CREATE_DATE,
                createdUser: resultData.CREATE_USER_NAME,
                updatedDate: resultData.UPDATE_DATE,
                updatedUser: resultData.UPDATE_USER_NAME,
              };
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return 0;
        }
      } catch (error) {
        console.log("insertTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return 0;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 新規登録
     */
    async createUser() {
      // メソッド名を定義
      const method_name="createUser"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(UserGet-start)
      addLog('INFO', {message: MSG.INFO["1006"],}, this.$route.name, method_name, TYPE[operation_type],);
      try {
        // 担当者テーブルデータ作成
        let returnFlg = await this.insertTable();
        switch (returnFlg) {
          case 0: // 通常エラー
          case 1: // 担当者アドレス重複
          case 2: // 管理者登録キャンセル
            return returnFlg;
          default:
            break;
       }
        // Cognitoの認証情報を取得
        const credentials = await Auth.currentCredentials();

        // パスワードを生成
        let password = '';
        while (!await this.validatePassword(password)) {
          password = await this.createPassword();
        }
        console.log({password});

        // Cognitoユーザー作成
        await this.createCognitoUser(credentials, password);

        // SESにメールアドレスを登録
        await this.configureSES(credentials);

        // 担当者にメールを送信
        await this.sendConfigurationMail(credentials, password);
        // ログ出力(UserGet-end)
        addLog('INFO', {message: MSG.INFO["1007"],}, this.$route.name, method_name, TYPE[operation_type],);
        return true;
      } catch (error) {
        // ログ出力(ユーザー情報取得でexceptionが発生)
        addLog('ERROR', {message: MSG.ERROR["3011"],}, this.$route.name, method_name, TYPE["0004"], error);
        return false;
      }
    },
    /**
     * パスワード作成処理
     */
    async createPassword() {
      // メソッド名を定義
      const method_name="createPassword"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // パスワードで使用する文字をセット(数字・アルファベット大文字小文字・記号)
      let chars = '';
      let letters = 'abcdefghijklmnopqrstuvwxyz';
      let numbers = '0123456789';
      let symbols = '!#$%&()*+,-./:;<=>?@[]^_`{|}~';
      chars += (letters + letters.toUpperCase() + numbers + symbols);

      // 8文字のパスワードを生成
      const length = 8;
      let password = '';
      for (let j = 0; j < length; j++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return password;
    },
    /**
     * パスワード妥当性検証処理
     */
    async validatePassword(password) {
      // メソッド名を定義
      const method_name="validatePassword"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 1文字以上必須
      let uppercaseMinCount = 1;
      let lowercaseMinCount = 1;
      let numberMinCount = 1;
      let specialMinCount = 1;
      
      // 各文字種の正規表現
      const UPPERCASE_RE = /([A-Z])/g;
      const LOWERCASE_RE = /([a-z])/g;
      const NUMBER_RE = /[\d]/g;
      const SPECIAL_CHAR_RE = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;

      // 各文字種を含むかチェック
      let uc = password.match(UPPERCASE_RE);
      let lc = password.match(LOWERCASE_RE);
      let n = password.match(NUMBER_RE);
      let sc = password.match(SPECIAL_CHAR_RE);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検証結果を返却
      return (
          uc && uc.length >= uppercaseMinCount &&
          lc && lc.length >= lowercaseMinCount &&
          n  && n.length >= numberMinCount &&
          sc && sc.length >= specialMinCount
      ) ? true : false;
    },
    /**
     * Cognitoユーザー作成処理
     */
    async createCognitoUser(credentials, password) {
      // メソッド名を定義
      const method_name="createCognitoUser"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(Amplify-start)
      addLog('INFO', {message: MSG.INFO["1008"],}, this.$route.name, method_name, TYPE[operation_type],);
      try {
        // Cognito User Poolの操作クラスにAWS認証情報を渡して初期化する
        const cognitoIdentity = new AWS.CognitoIdentityServiceProvider({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          region: AWS_DEFAULT_REGION,
        });
        // Cognitoの情報からユーザープールIDを取得
        const currentUserPoolUser = await Auth.currentUserPoolUser();
        const UserPoolId = currentUserPoolUser.pool.userPoolId;

        // Cognitoユーザー作成
        await cognitoIdentity.adminCreateUser({
          UserPoolId: UserPoolId,
          MessageAction: 'SUPPRESS',
          TemporaryPassword: password,
          UserAttributes: [
            {
              Name: 'email',
              Value: this.currentParams.managerAddress,
            },
          ],
          Username: this.currentParams.managerAddress.split('@')[0],
        }).promise();
        // console.log(userResult);

        // メールアドレスを認証済みに変更
        await cognitoIdentity.adminUpdateUserAttributes({
          UserAttributes: [
            {
              Name: 'email_verified',
              Value: 'true',
            },
          ],
          UserPoolId: UserPoolId,
          Username: this.currentParams.managerAddress.split('@')[0],
        }).promise();
        // ログ出力(Amplify-end)
        addLog('INFO', {message: MSG.INFO["1009"],}, this.$route.name, method_name, TYPE[operation_type],);
        return true;
      } catch (error) {
        console.log('createCognitoUser');
        console.log(error);
        // TODO エラー時処理
        // ログ出力(Amplifyの実行でexceptionが発生)
        addLog('ERROR', {message: MSG.ERROR["3012"],}, this.$route.name, method_name, TYPE["0004"], error);
        return false;
      }
    },
    /**
     * SESメールアドレス登録処理
     */
    async configureSES(credentials) {
      // メソッド名を定義
      const method_name="configureSES"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(Amplify-start)
      addLog('INFO', {message: MSG.INFO["1008"],}, this.$route.name, method_name, TYPE[operation_type],);
      try {
        const ses = new AWS.SES({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          region: AWS_DEFAULT_REGION,
        });

        const result = await ses.verifyEmailIdentity({EmailAddress: this.currentParams.managerAddress}).promise();
        console.log(result);
      } catch (error) {
        console.log('configureSES');
        console.log(error);
        // ログ出力(Amplifyの実行でexceptionが発生)
        addLog('ERROR', {message: MSG.ERROR["3012"],}, this.$route.name, method_name, TYPE["0004"], error);
      }
      // ログ出力(Amplify-end)
      addLog('INFO', {message: MSG.INFO["1009"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * メール送信処理
     */
    async sendConfigurationMail(credentials, password) {
      // メソッド名を定義
      const method_name="sendConfigurationMail"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(Amplify-start)
      addLog('INFO', {message: MSG.INFO["1008"],}, this.$route.name, method_name, TYPE[operation_type],);
      try {
        const ses = new AWS.SES({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          region: AWS_DEFAULT_REGION,
        });

        let message = '';
        message += this.currentParams.managerFamilyName + 'さん\n';
        message += '\n';
        message += '発注簿システムのアカウント登録が完了しました。\n';
        message += 'ログイン情報は下記のとおりです。\n';
        message += 'ログインURL：' + REGISTER_COMPLETE_LOGIN_URL + '\n';
        message += '初期パスワード: ' + password + '\n';
        message += '\n';
        message += '初回ログイン時にパスワードの変更が必要となります。\n';
        message += '数字、アルファベットの大文字・小文字、記号をそれぞれ1文字以上使用し、\n';
        message += '8文字以上のパスワードを設定して下さい。\n';
        message += '\n';
        message += '担当者から得意先にメールを送信するため、メールアドレスの登録が必要となります。\n';
        message += '「no-reply-aws@amazon.com」から\n';
        message += '「Amazon Web Services – Email Address Verification Request in region Asia Pacific (Tokyo)」\n';
        message += 'という件名のメールが届きます。\n';
        message += '本文中の\n';
        message += '「https://email-verification.ap-northeast-1.amazonaws.com...」\n';
        message += 'から始まるURLをクリックして下さい。\n';
        message += '「検証に成功しました」というページが表示されたら完了です。\n';
        message += '\n';
        message += '以上です。\n';
        message += 'どうぞよろしくお願いいたします。\n';
        message += '\n';
        message += '\n';
        message += '\n';
        message += 'サン電子システム管理者';

        let params = {
            Destination: {
                ToAddresses: [this.currentParams.managerAddress]
            },
            Message: {
                Body: {
                Text: { Data: message },
                },
        
                Subject: { Data: `発注簿システムアカウント登録完了` },
            },
            Source: REGISTER_COMPLETE_FROM_ADDRESS,
        };
        // ログ出力(Amplify-end)
        addLog('INFO', {message: MSG.INFO["1009"],}, this.$route.name, method_name, TYPE[operation_type],);
        return ses.sendEmail(params).promise();
      } catch (error) {
        console.log('sendConfigurationMail');
        console.log(error);
        // ログ出力(Amplifyの実行でexceptionが発生)
        addLog('ERROR', {message: MSG.ERROR["3012"],}, this.$route.name, method_name, TYPE["0004"], error);
      }
    },
    /**
     * 修正処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 管理者への変更処理
      let loginUserAuth = this.managerAuthSectionList.find(
        (items) => items.code == this.loginUserInfo.TANTOSHA_AUTH_KBN
      );
      let originalAuth = this.managerAuthSectionList.find(
        (items) => items.code == this.originalParams.managerAuthSection
      );
      let currentManagerAuth = this.managerAuthSectionList.find(
        (items) => items.code == this.currentParams.managerAuthSection
      );
      if (!loginUserAuth) loginUserAuth = { isAuthUser: false };
      if (!originalAuth) originalAuth = { isAuthUser: false };
      if (!currentManagerAuth) currentManagerAuth = { isAuthUser: false };
      // ログイン中ユーザーが管理者、かつ更新対象ユーザーの権限区分に差分あり
      // かつ画面での権限区分が管理者だった場合
      if (loginUserAuth.isAuthUser
          && (originalAuth.isAuthUser !== currentManagerAuth.isAuthUser)
          && currentManagerAuth.isAuthUser) {
        if (confirm(CONFIRM_MESSAGE_UPGRADE_AUTH_USER)) {
          return await this.updateAuthUser();
        } else {
          // 完了ダイアログを表示しないようフラグを立てる
          this.isCancelRegisterAuth = true;
          return true;
        }
      }

      // 更新用データ生成
      let sqlList = [];
      let authorizerFamilyName = this.currentParams.authorizerFamilyName == null ?
          null : `'${this.currentParams.authorizerFamilyName}'`;
      let authorizerAddress = this.currentParams.authorizerAddress == null ?
          null : `'${this.currentParams.authorizerAddress}'`;

      // 所属部門が変わっている場合、担当者コードの振り直しを行う
      if (this.currentParams.departmentCode !== this.originalDepartmentCode) {
        // コード最大値の取得
        let departmentCode = this.currentParams.departmentCode;
        let currentMaxCode = await this.getCurrentMaxManagerCode(departmentCode);
        if (!currentMaxCode) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return false;
        }
        sqlList.push(
          `UPDATE M_TANTOSHA SET` +
          ` LOGIN_USER_CD = '${this.currentParams.loginUserCode}',` +
          ` BUMON_CD = ${departmentCode},` +
          ` TANTOSHA_CD = ${currentMaxCode + 1},` +
          ` TANTOSHA_NAME = '${this.currentParams.managerFamilyName}',` +
          ` TANTOSHA_ADDRESS = '${this.currentParams.managerAddress}',` +
          ` TANTOSHA_AUTH_KBN = '${this.currentParams.managerAuthSection}',` +
          ` SHONINSHA_SIGN_NAME = ${authorizerFamilyName},` +
          ` SHONINSHA_ADDRESS = ${authorizerAddress},` +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}'` +
          ` WHERE LOGIN_USER_CD = '${this.currentParams.loginUserCode}'` +
          ` AND BUMON_CD = ${this.originalDepartmentCode}` +
          ` AND TANTOSHA_CD = ${this.currentParams.managerCode}`);
        sqlList.push(
          `SELECT * FROM M_TANTOSHA` +
          ` WHERE LOGIN_USER_CD = '${this.currentParams.loginUserCode}'` +
          ` AND BUMON_CD = ${departmentCode}` +
          ` AND TANTOSHA_CD = ${currentMaxCode + 1}`);
      } else {
        sqlList.push(
          `UPDATE M_TANTOSHA SET` +
          ` LOGIN_USER_CD = '${this.currentParams.loginUserCode}',` +
          ` BUMON_CD = ${this.currentParams.departmentCode},` +
          ` TANTOSHA_CD = ${this.currentParams.managerCode},` +
          ` TANTOSHA_NAME = '${this.currentParams.managerFamilyName}',` +
          ` TANTOSHA_ADDRESS = '${this.currentParams.managerAddress}',` +
          ` TANTOSHA_AUTH_KBN = '${this.currentParams.managerAuthSection}',` +
          ` SHONINSHA_SIGN_NAME = ${authorizerFamilyName},` +
          ` SHONINSHA_ADDRESS = ${authorizerAddress},` +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}'` +
          ` WHERE LOGIN_USER_CD = '${this.currentParams.loginUserCode}'` +
          ` AND BUMON_CD = ${this.currentParams.departmentCode}` +
          ` AND TANTOSHA_CD = ${this.currentParams.managerCode}`);
        sqlList.push(
          `SELECT * FROM M_TANTOSHA` +
          ` WHERE LOGIN_USER_CD = '${this.currentParams.loginUserCode}'` +
          ` AND BUMON_CD = ${this.currentParams.departmentCode}` +
          ` AND TANTOSHA_CD = ${this.currentParams.managerCode}`);
      }

      let condition = {
        SQLs: sqlList,
      };
      console.log("sqlList = " + sqlList)
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
              // DBから取得した値を設定
              // 取得結果をリストに追加
              let resultData = responseBody.data[0][0];
              this.currentParams = {
                loginUserCode: resultData.LOGIN_USER_CD,
                managerCode: resultData.TANTOSHA_CD,
                departmentCode: resultData.BUMON_CD,
                managerFamilyName: resultData.TANTOSHA_NAME,
                managerAddress: resultData.TANTOSHA_ADDRESS,
                managerAuthSection: resultData.TANTOSHA_AUTH_KBN,
                authorizerFamilyName: resultData.SHONINSHA_SIGN_NAME,
                authorizerAddress: resultData.SHONINSHA_ADDRESS,
                createdDate: resultData.CREATE_DATE,
                createdUser: resultData.CREATE_USER_NAME,
                updatedDate: resultData.UPDATE_DATE,
                updatedUser: resultData.UPDATE_USER_NAME,
              };

              // ログイン中ユーザーが自身の情報を更新した際はキャッシュも更新
              if (this.currentParams.loginUserCode === this.loginUserInfo.LOGIN_USER_CD
                  && this.currentParams.departmentCode === this.loginUserInfo.BUMON_CD) {
                console.log("updateTable：setUserData");
                await setUserData(this.loginUserInfo.LOGIN_USER_CD);
              }
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("updateTables : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("updateTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 未保存チェック用比較データの更新
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParams = JSON.parse(JSON.stringify(this.currentParams));
      this.originalDepartmentCode = this.currentParams.departmentCode;
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (
        JSON.stringify(this.currentParams) ==
        JSON.stringify(this.originalParams)
      );
    },
    /**
     * 既存データの更新チェック
     */
    isRequestedUpdate() {
      // メソッド名を定義
      const method_name="isRequestedUpdate"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.reqManagerItemString !== void 0;
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 担当者コードの最大値取得
     */
    async getCurrentMaxManagerCode(departmentCode) {
      // メソッド名を定義
      const method_name="getCurrentMaxManagerCode"
      // オペレーションタイプを定義
      const operation_type="0003"

      // 検索条件
      let condition = {
        where_options:
          "AND BUMON_CD = " +
          departmentCode +
          " ORDER BY TANTOSHA_CD DESC LIMIT 1",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE[operation_type],);
      // データ取得
      let resultData = null;
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_TANTOSHA, condition)
        );

        // 結果判定
        if (result.data.listM_TANTOSHA) {
          console.log("result 1");
          // データを取得できた場合
          // DBから取得した値を設定
          resultData = result.data.listM_TANTOSHA;
          let returnResult = 0;
          if (resultData.length > 0) {
            returnResult = resultData[0].TANTOSHA_CD;
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE[operation_type],);
          return returnResult;
        } else {
          console.log("result 2");
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE[operation_type],);
          return 0;
        }
      } catch (error) {
        console.log("result 2");
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE[operation_type], error);
        return null;
      }
    },
    /**
     * 管理者ユーザーの新規登録用処理
     */
    async insertAuthUser() {
      console.log("insertAuthUser");

      // メソッド名を定義
      const method_name="insertAuthUser"
      // オペレーションタイプを定義
      const operation_type="0003"

      // 初期設定から部門コードの一覧を取得
      let departmentCodeList = [];
      // 検索条件
      let condition = {
        where_options: " ORDER BY BUMON_CD ASC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.listM_SHOKISETTEI) {
          // データを取得できた場合
          // 部門コード選択用コンボボックスのリストに追加
          let resultData = result.data.listM_SHOKISETTEI;
          for (const data of resultData) {
            departmentCodeList.push(data.BUMON_CD);
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      console.log("departmentCodeList = "+ JSON.stringify(departmentCodeList));
      // 各部門の担当者コードを取得、INSERT文生成
      let insertSqlList = [];
      let insertSqls = '';
      let registerLoginUserCode = this.currentParams.managerAddress.split("@")[0];
      for (const departmentCode of departmentCodeList) {
        let currentMaxCode = await this.getCurrentMaxManagerCode(departmentCode);
        if (!currentMaxCode) {
          currentMaxCode = 0;
        }
        console.log("currentMaxCode = " + currentMaxCode);
        insertSqls = `INSERT INTO M_TANTOSHA VALUES (`;
        insertSqls += `'${registerLoginUserCode}',`;                      // LOGIN_USER_CD
        insertSqls += `${departmentCode},`;                               // BUMON_CD
        insertSqls += `${currentMaxCode + 1},`;                           // TANTOSHA_CD
        insertSqls += `'${this.currentParams.managerFamilyName}',`;       // TANTOSHA_NAME
        insertSqls += `'${this.currentParams.managerAddress}',`;          // TANTOSHA_ADDRESS
        insertSqls += `'${this.currentParams.managerAuthSection}',`;      // TANTOSHA_AUTH_KBN
        if (this.currentParams.authorizerFamilyName) {                    // SHONINSHA_SIGN_NAME
          insertSqls += `'${this.currentParams.authorizerFamilyName}',`;
        } else {
          insertSqls +=  'null,';
        }
        if(this.currentParams.authorizerAddress) {                       // SHONINSHA_ADDRESS
          insertSqls += `'${this.currentParams.authorizerAddress}',`;
        } else {
          insertSqls += 'null,';
        }
        insertSqls += ` CURDATE(),`;                                      // CREATE_DATE
        insertSqls += ` '${this.loginUserInfo.LOGIN_USER_CD}',`;          // CREATE_USER_NAME
        insertSqls += ` CURDATE(),`;                                      // UPDATE_DATE
        insertSqls += ` '${this.loginUserInfo.LOGIN_USER_CD}')`;          // UPDATE_USER_NAME
        insertSqlList.push(insertSqls);
      }

      // SELECT文生成
      let selectSql = '';
      selectSql += `SELECT * FROM M_TANTOSHA `;
      selectSql += ` WHERE LOGIN_USER_CD = '${registerLoginUserCode}'`;
      selectSql += ` AND BUMON_CD = ${!this.currentParams.departmentCode ?
          AUTH_USER_DEFAULT_DEPARTMENT : this.currentParams.departmentCode}`;

      insertSqlList.push(selectSql);

      condition = {
        SQLs: insertSqlList,
      };
      console.log("condition = " + condition);
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return 0;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              console.log(responseBody.data);
              for (const data of responseBody.data) {
                if (data[0].BUMON_CD == AUTH_USER_DEFAULT_DEPARTMENT) {
                  let resultData = data[0];
                  // DBから取得した値を設定
                  this.currentParams = {
                    loginUserCode: resultData.LOGIN_USER_CD,
                    managerCode: resultData.TANTOSHA_CD,
                    departmentCode: resultData.BUMON_CD,
                    managerFamilyName: resultData.TANTOSHA_NAME,
                    managerAddress: resultData.TANTOSHA_ADDRESS,
                    managerAuthSection: resultData.TANTOSHA_AUTH_KBN,
                    authorizerFamilyName: resultData.SHONINSHA_SIGN_NAME,
                    authorizerAddress: resultData.SHONINSHA_ADDRESS,
                    createdDate: resultData.CREATE_DATE,
                    createdUser: resultData.CREATE_USER_NAME,
                    updatedDate: resultData.UPDATE_DATE,
                    updatedUser: resultData.UPDATE_USER_NAME,
                  };
                  break;
                }
              }
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return 0;
        }
      } catch (error) {
        console.log("insertTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return 0;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 一般から管理者への切り替え時用処理
     */
    async updateAuthUser() {
      console.log("updateAuthUser");

      // メソッド名を定義
      const method_name="updateAuthUser"
      // オペレーションタイプを定義
      const operation_type="0003"

      // 初期設定から部門コードの一覧を取得
      let departmentCodeList = [];
      // 検索条件
      let condition = {
        where_options: " ORDER BY BUMON_CD ASC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.listM_SHOKISETTEI) {
          // データを取得できた場合
          // 部門コード選択用コンボボックスのリストに追加
          let resultData = result.data.listM_SHOKISETTEI;
          for (const data of resultData) {
            // すでに登録のあるレコードはUPDATEのため、担当者コードは取得しない
            if (data.BUMON_CD == this.originalParams.departmentCode) {
              continue;
            }
            departmentCodeList.push(data.BUMON_CD);
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      console.log("departmentCodeList = "+ JSON.stringify(departmentCodeList));
      // 各部門の担当者コードを取得、INSERT文生成
      let insertSqlList = [];
      let insertSqls = '';
      let registerLoginUserCode = this.currentParams.managerAddress.split("@")[0];
      for (const departmentCode of departmentCodeList) {
        let currentMaxCode = await this.getCurrentMaxManagerCode(departmentCode);
        // 最大値の登録がない場合は0に上書き
        if (!currentMaxCode) {
          currentMaxCode = 0;
        }
        console.log("currentMaxCode = " + currentMaxCode);
        insertSqls = `INSERT INTO M_TANTOSHA VALUES (`;
        insertSqls += `'${registerLoginUserCode}',`;                      // LOGIN_USER_CD
        insertSqls += `${departmentCode},`;                               // BUMON_CD
        insertSqls += `${currentMaxCode + 1},`;                           // TANTOSHA_CD
        insertSqls += `'${this.currentParams.managerFamilyName}',`;       // TANTOSHA_NAME
        insertSqls += `'${this.currentParams.managerAddress}',`;          // TANTOSHA_ADDRESS
        insertSqls += `'${this.currentParams.managerAuthSection}',`;      // TANTOSHA_AUTH_KBN
        if (this.currentParams.authorizerFamilyName) {                    // SHONINSHA_SIGN_NAME
          insertSqls += `'${this.currentParams.authorizerFamilyName}',`;
        } else {
          insertSqls +=  'null,';
        }
        if(this.currentParams.authorizerAddress) {                       // SHONINSHA_ADDRESS
          insertSqls += `'${this.currentParams.authorizerAddress}',`;
        } else {
          insertSqls += 'null,';
        }
        insertSqls += ` CURDATE(),`;                                      // CREATE_DATE
        insertSqls += ` '${this.loginUserInfo.LOGIN_USER_CD}',`;          // CREATE_USER_NAME
        insertSqls += ` CURDATE(),`;                                      // UPDATE_DATE
        insertSqls += ` '${this.loginUserInfo.LOGIN_USER_CD}')`;          // UPDATE_USER_NAME
        insertSqlList.push(insertSqls);
      }

      // 更新用SQLを作成
      let authorizerFamilyName = this.currentParams.authorizerFamilyName == null ?
          null : `'${this.currentParams.authorizerFamilyName}'`;
      let authorizerAddress = this.currentParams.authorizerAddress == null ?
          null : `'${this.currentParams.authorizerAddress}'`;

      insertSqlList.push(
          `UPDATE M_TANTOSHA SET` +
          ` LOGIN_USER_CD = '${registerLoginUserCode}',` +
          ` BUMON_CD = ${this.originalParams.departmentCode},` +
          ` TANTOSHA_CD = ${this.originalParams.managerCode},` +
          ` TANTOSHA_NAME = '${this.currentParams.managerFamilyName}',` +
          ` TANTOSHA_ADDRESS = '${this.currentParams.managerAddress}',` +
          ` TANTOSHA_AUTH_KBN = '${this.currentParams.managerAuthSection}',` +
          ` SHONINSHA_SIGN_NAME = ${authorizerFamilyName},` +
          ` SHONINSHA_ADDRESS = ${authorizerAddress},` +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}'` +
          ` WHERE LOGIN_USER_CD = '${this.currentParams.loginUserCode}'` +
          ` AND BUMON_CD = ${this.originalDepartmentCode}`);

      // SELECT文生成
      let selectSql = '';
      selectSql += `SELECT * FROM M_TANTOSHA `;
      selectSql += ` WHERE LOGIN_USER_CD = '${registerLoginUserCode}'`;
      selectSql += ` AND BUMON_CD = ${this.originalDepartmentCode}`;

      insertSqlList.push(selectSql);

      condition = {
        SQLs: insertSqlList,
      };
      console.log("condition = " + condition.SQLs);
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return 0;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              console.log(responseBody.data);
              for (const data of responseBody.data) {
                if (data[0].BUMON_CD == AUTH_USER_DEFAULT_DEPARTMENT) {
                  let resultData = data[0];
                  // DBから取得した値を設定
                  this.currentParams = {
                    loginUserCode: resultData.LOGIN_USER_CD,
                    managerCode: resultData.TANTOSHA_CD,
                    departmentCode: resultData.BUMON_CD,
                    managerFamilyName: resultData.TANTOSHA_NAME,
                    managerAddress: resultData.TANTOSHA_ADDRESS,
                    managerAuthSection: resultData.TANTOSHA_AUTH_KBN,
                    authorizerFamilyName: resultData.SHONINSHA_SIGN_NAME,
                    authorizerAddress: resultData.SHONINSHA_ADDRESS,
                    createdDate: resultData.CREATE_DATE,
                    createdUser: resultData.CREATE_USER_NAME,
                    updatedDate: resultData.UPDATE_DATE,
                    updatedUser: resultData.UPDATE_USER_NAME,
                  };
                  break;
                }
              }
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          // ログイン中ユーザーが自身の情報を更新した際はキャッシュも更新
          if (this.currentParams.loginUserCode === this.loginUserInfo.LOGIN_USER_CD
              && this.currentParams.departmentCode === this.loginUserInfo.BUMON_CD) {
            console.log("updateAuthUser：setUserData");
            await setUserData(this.loginUserInfo.LOGIN_USER_CD);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return 0;
        }
      } catch (error) {
        console.log("insertTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return 0;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
  },
};
</script>
